.App {
  text-align: center;
  color: #fff;
    background-color: #000;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);

        font-family: 'Playfair Display', serif;
}

p{
  font-size: calc(10px + 1vmin);

      font-family: Georgia;
      color: #ffe400;



}
